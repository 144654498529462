import React from 'react';

import Button from '../Button/Button';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import RightArrow from '../../Icons/RightArrow';
import LeftArrow from '../../Icons/LeftArrow';
import QuoteCard from '../QuoteCard/QuoteCard';
import DotIcon from '../../Icons/DotIcon';

const Quotes = ({ quotes, images }) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  const CustomDot = ({ onClick, active }) => {
    return (
      <li className="custom-dots" onClick={() => onClick()}>
        <DotIcon active={active} />
      </li>
    );
  };

  return (
    <Carousel
      partialVisible
      responsive={responsive}
      arrows={false}
      showDots
      customDot={<CustomDot />}
    >
      {quotes.map(({ quote, name, employee, src }, id) => (
        <QuoteCard
          key={id}
          quote={quote}
          name={name}
          employee={employee}
          image={images[id].src.childImageSharp.fluid.src}
        />
      ))}
    </Carousel>
  )
};

export default Quotes;