import React from 'react';
import parse from 'html-react-parser';

import { string } from 'prop-types';

import quoteIconSrc from '../../../assets/home/quote-icon.svg';

const QuoteCard = ({ quote, image, employee, name, className = '' }) => (
  <div key={name} className={`quote-wrapper ${className}`}>
    <div className="quote-card">
      <div className="row no-gutters">
        <div className="col-2">
          <img src={quoteIconSrc} />
        </div>
        <div className="col-10">
          {parse(quote)}
          <br />
          {name && <div className={`author ${!employee ? 'primary' : ''}`}>{name}</div>}
        </div>
      </div>
      <div className="quote-card-image row align-items-center">
        <img
          width="180"
          height="180"
          src={image}
          alt={`${name} avatar`}
        />
        <div className={name ? 'with-name' : ''}>
          <h4>{employee}</h4>
        </div>
      </div>
    </div>
  </div>
);

QuoteCard.propTypes = {
  quote: string.isRequired,
  image: string.isRequired,
  employee: string,
  name: string,
  className: string
};

export default QuoteCard;
